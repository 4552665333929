import { type Network } from "@/types/utila";
const sourceNetworks: Network[] = [
  {
    display_name: "Bitcoin Testnet",
    internal_name: "BITCOIN_TESTNET",
    native_currency: "BTC",
    logo: "https://cdn.lux.network/bridge/networks/bitcoin_mainnet.png",
    is_testnet: true,
    is_featured: true,
    average_completion_time: "00:04:39.5276290",
    chain_id: null,
    status: "active",
    type: "btc",
    deposit_address: {
      address: "",
    },
    refuel_amount_in_usd: 1,
    transaction_explorer_template: "https://blockstream.info/testnet/tx/{0}",
    account_explorer_template: "https://blockstream.info/testnet/address/{0}",
    node: "",
    currencies: [
      {
        name: "BTC",
        asset: "BTC",
        contract_address: "",
        logo: "https://cdn.lux.network/bridge/currencies/btc.png",
        decimals: 8,
        status: "active",
        is_deposit_enabled: true,
        is_withdrawal_enabled: false,
        is_refuel_enabled: false,
        max_withdrawal_amount: 0,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: true,
      },
    ],
  },
  {
    display_name: "Ethereum Sepolia",
    internal_name: "ETHEREUM_SEPOLIA",
    native_currency: "ETH",
    logo: "https://cdn.lux.network/bridge/networks/ethereum_sepolia.png",
    is_testnet: true,
    is_featured: true,
    average_completion_time: "00:01:04.0494430",
    chain_id: 11155111,
    status: "active",
    type: "evm",
    deposit_address: {
      address: "",
    },
    refuel_amount_in_usd: 0.5,
    transaction_explorer_template: "https://sepolia.etherscan.io/tx/{0}",
    account_explorer_template: "https://sepolia.etherscan.io/address/{0}",
    node: "https://old-green-glade.ethereum-sepolia.quiknode.pro/0523b575936957f0e7eae638096d19465aae8f8c/",
    currencies: [
      {
        name: "ETH",
        asset: "ETH",
        logo: "https://cdn.lux.network/bridge/currencies/ethereum_eth.svg",
        contract_address: "0x0000000000000000000000000000000000000000",
        decimals: 18,
        status: "active",
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: true,
      }
    ],
  },
  {
    display_name: 'BSC Testnet',
    internal_name: 'BSC_TESTNET',
    native_currency: 'BNB',
    logo: 'https://cdn.lux.network/bridge/networks/bsc_mainnet.png',
    is_testnet: true,
    is_featured: false,
    average_completion_time: '00:01:04.0494430',
    chain_id: 97,
    status: 'active',
    type: 'evm',
    refuel_amount_in_usd: 0.5,
    transaction_explorer_template: 'https://testnet.bscscan.com/tx/{0}',
    account_explorer_template: 'https://testnet.bscscan.com/address/{0}',
    node: 'https://data-seed-prebsc-1-s1.bnbchain.org:8545',
    currencies: [
      {
        name: 'BNB',
        asset: 'BNB',
        logo: 'https://cdn.lux.network/bridge/networks/bsc_mainnet.png',
        contract_address: '0x0000000000000000000000000000000000000000',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: true,
      }
    ],
  },
  {
    display_name: "Solana Devnet",
    internal_name: "SOLANA_DEVNET",
    native_currency: "SOL",
    logo: "https://cdn.lux.network/bridge/networks/solana_devnet.png",
    is_testnet: true,
    is_featured: false,
    average_completion_time: "00:03:07.9874770",
    chain_id: null,
    status: "active",
    type: "solana",
    deposit_address: {
      address: "",
    },
    refuel_amount_in_usd: 1,
    transaction_explorer_template: "https://explorer.solana.com/?cluster=devnet/tx/{0}",
    account_explorer_template: "https://explorer.solana.com/?cluster=devnet/address/{0}",
    node: "https://api.devnet.solana.com",
    currencies: [
      {
        name: "SOL",
        asset: "SOL",
        contract_address: null,
        logo: "https://cdn.lux.network/bridge/currencies/sol.png",
        decimals: 9,
        status: "active",
        is_deposit_enabled: false,
        is_withdrawal_enabled: false,
        is_refuel_enabled: false,
        max_withdrawal_amount: 0,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: true,
      },
    ],
  },
];
const destinationNetworks: Network[] = [
  {
    display_name: 'Lux Testnet',
    internal_name: 'LUX_TESTNET',
    native_currency: 'LUX',
    logo: 'https://cdn.lux.network/bridge/networks/lux_mainnet.png',
    is_testnet: true,
    is_featured: true,
    average_completion_time: '00:00:07.7777777',
    chain_id: 96368,
    status: 'active',
    type: 'evm',
    refuel_amount_in_usd: 1,
    transaction_explorer_template: 'https://explore.lux-test.network/tx/{0}',
    account_explorer_template: 'https://explore.lux-test.network/address/{0}',
    node: 'https://api.lux-test.network',
    currencies: [
      {
        name: 'LUX',
        asset: 'LUX',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lux.svg',
        contract_address: '0x0000000000000000000000000000000000000000',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: true,
      },
      {
        name: 'Lux BTC',
        asset: 'LBTC',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lbtc.svg',
        contract_address: '0x1E48D32a4F5e9f08DB9aE4959163300FaF8A6C8e',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux ETH',
        asset: 'LETH',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/leth.svg',
        contract_address: '0x60E0a8167FC13dE89348978860466C9ceC24B9ba',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux Dollar',
        asset: 'LUSD',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lusd.svg',
        contract_address: '0x848Cff46eb323f323b6Bbe1Df274E40793d7f2c2',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux ZOO',
        asset: 'LZOO',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lzoo.svg',
        contract_address: '0x5E5290f350352768bD2bfC59c2DA15DD04A7cB88',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux BNB',
        asset: 'LBNB',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lbnb.svg',
        contract_address: '0x6EdcF3645DeF09DB45050638c41157D8B9FEa1cf',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux POL',
        asset: 'LPOL',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lpol.svg',
        contract_address: '0x28BfC5DD4B7E15659e41190983e5fE3df1132bB9',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux CELO',
        asset: 'LCELO',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lcelo.svg',
        contract_address: '0x3078847F879A33994cDa2Ec1540ca52b5E0eE2e5',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux FTM',
        asset: 'LFTM',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lftm.svg',
        contract_address: '0x8B982132d639527E8a0eAAD385f97719af8f5e04',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux xDAI',
        asset: 'LXDAI',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/ldai.svg',
        contract_address: '0x7dfb3cBf7CF9c96fd56e3601FBA50AF45C731211',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux SOL',
        asset: 'LSOL',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lsol.svg',
        contract_address: '0x26B40f650156C7EbF9e087Dd0dca181Fe87625B7',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Lux TON',
        asset: 'LTON',
        logo: 'https://cdn.lux.network/bridge/currencies/lux/lton.svg',
        contract_address: '0x3141b94b89691009b950c96e97Bff48e0C543E3C',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      }
    ],
  },
  {
    display_name: 'Zoo Testnet',
    internal_name: 'ZOO_TESTNET',
    native_currency: 'ZOO',
    logo: 'https://cdn.lux.network/bridge/networks/zoo_testnet.svg',
    is_testnet: true,
    is_featured: true,
    average_completion_time: '00:00:07.7777777',
    chain_id: 200201,
    status: 'active',
    type: 'evm',
    refuel_amount_in_usd: 1,
    transaction_explorer_template: 'https://explore.zoo-test.network/tx/{0}',
    account_explorer_template: 'https://explore.zoo-test.network/address/{0}',
    node: 'https://api.zoo-test.network',
    currencies: [
      {
        name: 'ZOO',
        asset: 'ZOO',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo.svg',
        contract_address: '0x0000000000000000000000000000000000000000',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: true,
      },
      {
        name: 'Zoo BTC',
        asset: 'ZBTC',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zbtc.svg',
        contract_address: '0x1E48D32a4F5e9f08DB9aE4959163300FaF8A6C8e',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo ETH',
        asset: 'ZETH',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zeth.svg',
        contract_address: '0x60E0a8167FC13dE89348978860466C9ceC24B9ba',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo Dollar',
        asset: 'ZUSD',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zusd.svg',
        contract_address: '0x848Cff46eb323f323b6Bbe1Df274E40793d7f2c2',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo LUX',
        asset: 'ZLUX',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zlux.png',
        contract_address: '0x5E5290f350352768bD2bfC59c2DA15DD04A7cB88',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo BNB',
        asset: 'ZBNB',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zbnb.svg',
        contract_address: '0x6EdcF3645DeF09DB45050638c41157D8B9FEa1cf',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo POL',
        asset: 'ZPOL',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zpol.svg',
        contract_address: '0x28BfC5DD4B7E15659e41190983e5fE3df1132bB9',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo CELO',
        asset: 'ZCELO',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zcelo.svg',
        contract_address: '0x3078847F879A33994cDa2Ec1540ca52b5E0eE2e5',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo FTM',
        asset: 'ZFTM',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zftm.svg',
        contract_address: '0x8B982132d639527E8a0eAAD385f97719af8f5e04',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo xDAI',
        asset: 'ZXDAI',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zdai.svg',
        contract_address: '0x7dfb3cBf7CF9c96fd56e3601FBA50AF45C731211',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo SOL',
        asset: 'ZSOL',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zsol.svg',
        contract_address: '0x26B40f650156C7EbF9e087Dd0dca181Fe87625B7',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo TON',
        asset: 'ZTON',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zton.svg',
        contract_address: '0x3141b94b89691009b950c96e97Bff48e0C543E3C',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      },
      {
        name: 'Zoo ADA',
        asset: 'ZADA',
        logo: 'https://cdn.lux.network/bridge/currencies/zoo/zada.svg',
        contract_address: '0x8b34152832b8ab4a3274915675754AA61eC113F0',
        decimals: 18,
        status: 'active',
        is_deposit_enabled: true,
        is_withdrawal_enabled: true,
        is_refuel_enabled: false,
        max_withdrawal_amount: 1,
        deposit_fee: 0.1,
        withdrawal_fee: 0.1,
        source_base_fee: 0.1,
        destination_base_fee: 0.1,
        is_native: false,
      }
    ]
  }
];
export default { sourceNetworks, destinationNetworks };
